// 维护管理
import request from '@/utils/util.js'

// 新增
export function addMaintainDataAPI(data) {
	return request.post('/maintain', data)
}

// 修改
export function updateMaintainDataAPI(id, data) {
	return request.put(`/maintain/${id}`, data)
}

// 列表
export function getMaintainDataAPI(data) {
	return request.get('/maintain', data)
}

// 删除
export function delMaintainDataAPI(id) {
	return request.del(`/maintain/${id}`)
}
