<template>
  <div>
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="房间名字" prop="room_keyword">
        <el-input v-model="queryForm.room_keyword" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="记录人" prop="user_keyword">
        <el-input v-model="queryForm.user_keyword" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="目前状态" prop="state">
        <el-select v-model="queryForm.state" placeholder="请选择">
          <el-option label="未处理" :value="0"></el-option>
          <el-option label="已处理" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="记录时间">
        <el-date-picker v-model="dateRange" @change="handleRecordDateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="房间名字" prop="room"></el-table-column>
      <el-table-column label="记录时间" prop="record_time"></el-table-column>
      <el-table-column label="情况描述" prop="record_content"></el-table-column>
      <el-table-column label="处理状态">
        <template v-slot="{ row }">
          <el-tag v-if="row.state === 0" type="warning" size="small">未处理</el-tag>
          <el-tag v-else type="success" size="small">已处理</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="处理意见" prop="remark"></el-table-column>
      <el-table-column label="记录人" prop="user_info.name"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleView(row)" type="primary" class="margin-r-10">查看</el-link>
          <el-link @click="handleEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm @confirm="handleDel(row.id)" title="确定删除吗？">
            <el-link slot="reference" type="danger">删除</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getMaintainData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <!-- 新增、编辑的弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :before-close="closeDialog" :close-on-click-modal="false"
      width="550px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="房间名字" prop="room">
          <el-cascader v-model="form.room" :options="houseCascaderData" :props="cascaderProps"
            class="el-input_inner--rewrite"></el-cascader>
        </el-form-item>
        <el-form-item label="记录时间" prop="record_time">
          <el-date-picker v-model="form.record_time" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss" class="el-input_inner--rewrite">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="情况描述" prop="record_content">
          <el-input v-model="form.record_content" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="处理状态" prop="state">
          <el-radio-group v-model="form.state">
            <el-radio :label="0">未处理</el-radio>
            <el-radio :label="1">已处理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="处理意见" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
      </span>
    </el-dialog>

    <!-- 查看的弹窗 -->
    <el-dialog title="查看记录" :visible.sync="showPreviewDialog" width="500px">
      <el-descriptions v-if="curRowData !== null" :column="1" border>
        <el-descriptions-item label="房间名字">{{ curRowData.room }}</el-descriptions-item>
        <el-descriptions-item label="记录时间">{{ curRowData.record_time }}</el-descriptions-item>
        <el-descriptions-item label="情况描述">{{ curRowData.record_content }}</el-descriptions-item>
        <el-descriptions-item label="处理状态">
          <el-tag v-if="curRowData.state === 0" type="warning" size="small">未处理</el-tag>
          <el-tag v-else type="success" size="small">已处理</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="处理意见">{{ curRowData.remark }}</el-descriptions-item>
        <el-descriptions-item label="记录人">{{ curRowData.user_info.name }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer">
        <el-button @click="showPreviewDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'

  import {
    addMaintainDataAPI,
    getMaintainDataAPI,
    updateMaintainDataAPI,
    delMaintainDataAPI,
  } from '@/api/maintain.js'
  import {
    getHouseCascaderDataAPI,
  } from '@/api/base-data.js'

  export default {
    name: 'Maintain',
    data() {
      return {
        UPLOAD_URL,
        // 园区、楼栋、楼层、房间、床位
        houseCascaderData: [],
        cascaderProps: {
          label: 'name',
          value: 'name'
        },
        // 搜索
        dateRange: [],
        queryForm: {
          page: 1,
          user_keyword: '',
          room_keyword: '',
          state: '',
          start: '',
          end: ''
        },
        // 表格
        tableData: [],
        total: 0,
        pageSize: 0,
        tableLoading: false,
        curRowData: null,
        // 预览
        showPreviewDialog: false,
        // 表单相关
        showDialog: false,
        dialogTitle: '',
        submitting: false,
        form: {
          room: '',
          user_id: '',
          record_time: '',
          record_content: '',
          state: 0,
          remark: '',
        },
        rules: {
          room: [{
            required: true,
            message: '房间名字不能为空',
            trigger: 'change'
          }],
          record_time: [{
            required: true,
            message: '记录时间不能为空',
            trigger: 'change'
          }],
          record_content: [{
            required: true,
            message: '情况描述不能为空',
            trigger: 'change'
          }],
          state: [{
            required: true,
            message: '处理状态不能为空',
            trigger: 'change'
          }],
          remark: [{
            required: true,
            message: '处理意见不能为空',
            trigger: 'change'
          }],
          user_id: [{
            required: true,
            message: '记录人不能为空',
            trigger: 'change'
          }],
        }
      }
    },
    created() {
      this.getMaintainData()
      this.getHouseCascaderData()
    },
    methods: {
      // 新增按钮
      handleAdd() {
        this.showDialog = true
        this.dialogTitle = '新增'
      },
      // 搜索按钮
      handleSearch() {
        this.queryForm.page = 1
        this.getMaintainData()
      },
      // 记录时间选择 --顶部搜索框
      handleRecordDateChange(e) {
        this.queryForm.start = e[0]
        this.queryForm.end = e[1]
      },
      // 重置按钮
      handleReset() {
        this.queryForm.start = ''
        this.queryForm.end = ''
        this.dateRange = []
        this.$refs.queryForm.resetFields()
      },
      closeDialog() {
        this.$refs.form.resetFields()
        this.showDialog = false
      },
      // 删除按钮
      handleDel(id) {
        delMaintainDataAPI(id).then(res => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.getMaintainData()
          }
        })
      },
      // 查看按钮
      handleView(row) {
        this.showPreviewDialog = true
        this.curRowData = row
      },
      // 编辑按钮
      handleEdit(row) {
        this.showDialog = true
        this.dialogTitle = '编辑'
        this.curRowData = JSON.parse(JSON.stringify(row))
        // 设置form
        for (let key in this.form) {
          this.form[key] = this.curRowData[key]
        }
        // 回显房间
        this.form.room = this.curRowData.room.split('-')
      },
      // 提交按钮
      handleSubmit() {
        // 校验表单
        this.$refs.form.validate(isValid => {
          if (!isValid) return

          this.submitting = true
          const copyForm = JSON.parse(JSON.stringify(this.form))
          copyForm.room = copyForm.room.join('-')
          // 提交
          if (this.dialogTitle === '新增') {
            addMaintainDataAPI(copyForm).then(res => {
              this.$message.success(res.data)
              this.closeDialog()
              this.getMaintainData()
            }).finally(() => this.submitting = false)
          } else {
            updateMaintainDataAPI(this.curRowData.id, copyForm).then(res => {
              this.$message.success(res.data)
              this.closeDialog()
              this.getMaintainData()
            }).finally(() => this.submitting = false)
          }
        })
      },
      // 获取照护数据
      getMaintainData() {
        this.tableLoading = true

        getMaintainDataAPI(this.queryForm).then(res => {
          this.tableData = res.data
          this.total = res.total
          this.pageSize = res.per_page
          this.tableLoading = false
        })
      },
      // 获取级联数据
      getHouseCascaderData() {
        getHouseCascaderDataAPI({
          level: 3
        }).then(res => this.houseCascaderData = res.data)
      }
    }
  }
</script>

<style scoped>
</style>